import React, { useState, useEffect } from 'react';
import { FaSpinner, FaAngleLeft, FaAngleRight, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import { ProductList, CustomModal } from './home';
import { formatPrice } from '../../util/format';
import logoWhatsapp from '../../assets/images/icon-whatsapp-480.png';
import { db, storage } from '../../services/firebaseConnection';
import { getDocs, collection, orderBy, query } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import Header from '../../components/Header';

export default function Home() {
  const [isVisible, setIsVisible] = useState(true);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(1);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % products.length);
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + products.length) % products.length);
  };

  useEffect(() => {
    async function loadProducts() {
      const productsRef = collection(db, 'products');
      const queryRef = query(productsRef, orderBy('title', 'asc'));
      try {
        const snapshot = await getDocs(queryRef);
        const promises = snapshot.docs.map(async (doc) => {
          const productData = doc.data();
          const imageUrl = await getDownloadURL(ref(storage, ['products', productData.image].join('/')));
          return {
            id: doc.id,
            title: productData.title,
            price: productData.price,
            priceFormatted: formatPrice(productData.price),
            image: imageUrl,
          };
        });
        const productList = await Promise.all(promises);
        setProducts(productList);
        setLoading(false);
      } catch (error) {
        console.log('Erro ao carregar produtos: ' + error);
      }
    }
    loadProducts();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 7000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 7000);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentImageIndex]);

  return (
    <>
      <Header />
      <ProductList>
        {loading ? (
          <div className="loading">
            <FaSpinner className="spinner" />
          </div>
        ) : (
          products.map((product, index) => (
            <div className='cardProd' key={product.id} onClick={() => openModal(index)}>
              <img src={product.image} alt={product.title} loading="lazy" />
              <div className='content'>
                <strong>{product.title}</strong>
                <span>{product.price ? product.priceFormatted : ""}</span>
                <a
                  aria-label="Chat on WhatsApp"
                  href={`https://wa.me/5548991293880?text=${product.title}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="whatsapp-button"
                >
                  <img alt="WhatsApp" src={logoWhatsapp} className="whatsapp-icon" loading="lazy" width={480} height={480} />
                  Pedidos via WhatsApp
                </a>
              </div>
            </div>
          ))
        )}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Imagem Modal"
          ariaHideApp={false}
        >
          <CustomModal isVisible={isVisible}>
            <FaTimes className="modal-icons close-icon" onClick={closeModal} />
            {products[currentImageIndex] && (
              <>
                <img
                  src={products[currentImageIndex].image}
                  alt={products[currentImageIndex].title}
                  loading="lazy"
                />
                <div className="overlay-text">
                  <strong>{products[currentImageIndex].title}</strong>
                  <p>{products[currentImageIndex].price ? products[currentImageIndex].priceFormatted : ""}</p>
                </div>
              </>
            )}
            <FaAngleLeft className="modal-icons left-icon" onClick={goToPreviousImage} />
            <FaAngleRight className="modal-icons right-icon" onClick={goToNextImage} />
          </CustomModal>
        </Modal>
      </ProductList>
    </>
  );
}