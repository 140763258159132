import { useState, useEffect } from 'react';
import './logo.css'

import { storage } from '../../services/firebaseConnection'
import { getDownloadURL, ref } from 'firebase/storage';

export function Logo(){
  const [logoImageUrl, setLogoImageUrl] = useState('');

  useEffect(() => {
    async function fetchLogoImage() {
      try {
        const imageUrl = await getDownloadURL(ref(storage, 'logo_quadrado_sem_fundo.png'));
        setLogoImageUrl(imageUrl);
      } catch (error) {
        console.error('Erro ao carregar logo:', error);
      }
    }
  
    fetchLogoImage();
  }, []);

  return(
    <div className='container'>
      <img src={logoImageUrl} alt='Viju Decora' className='logo-image' />
    </div>
  )
}