import React from 'react';
import { Link } from 'react-router-dom';
import { MdShoppingBasket } from 'react-icons/md';

import logo from '../../assets/images/logo_quadrado_sem_fundo.png';
import { Container, Cart } from './header';

export default function Header() {
  return (
    <Container>
      <Link to="/">
        <img src={logo} alt="Viju Decora" className="logo" width={541} height={444} />
      </Link>

      <Cart to="/">
        <div>
          <strong>Meu carrinho</strong>
          <span data-testid="cart-size">0</span>
        </div>
        <MdShoppingBasket size={36} color="#FFF" />
      </Cart>
    </Container>
  );
};