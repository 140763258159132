import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDfDmdx2gFNGOxe5sC27JmJexznKNXohg0",
  authDomain: "vijudecora.firebaseapp.com",
  projectId: "vijudecora",
  storageBucket: "vijudecora.appspot.com",
  messagingSenderId: "1012932046357",
  appId: "1:1012932046357:web:2efb02f8466971be46a81c",
  measurementId: "G-V3BJVPC4ZH"
};

const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp)
const storage = getStorage(firebaseApp);

export { db, auth, storage };