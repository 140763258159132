import './admin.css'

import { Header } from '../../components/Header-admin'
import { Input } from '../../components/Input'
import { formatPrice } from '../../util/format';

import { MdAddLink } from 'react-icons/md'
import { FiTrash2 } from 'react-icons/fi'
import { useState, useEffect } from 'react'

import { db } from '../../services/firebaseConnection'

import {
  addDoc,
  collection,
  onSnapshot,
  query,
  orderBy,
  doc,
  deleteDoc
} from 'firebase/firestore'

import { toast } from 'react-toastify'

export default function Admin() {
  const [titleInput, setTitleInput] = useState('')
  const [priceInput, setPriceInput] = useState('')
  const [imageInput, setImageInput] = useState('')

  const [products, setProducts] = useState([])

  useEffect(() => {
    const productsRef = collection(db, 'products')
    const queryRef = query(productsRef, orderBy('title', 'asc'))

    onSnapshot(queryRef, (snapshot) => {
      let lista = [];

      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          title: doc.data().title,
          price: doc.data().price,
          priceFormatted: formatPrice(doc.data().price),
          image: doc.data().image
        })
      })

      setProducts(lista);
    })

  }, [])

  async function handleRegister(e) {
    e.preventDefault();

    if (titleInput === '' || priceInput === '' || imageInput === '') {
      toast.warn('Preencha todos os campos!')
      return;
    }

    addDoc(collection(db, 'products'), {
      title: titleInput,
      price: priceInput,
      image: imageInput,
    })
      .then(() => {
        setTitleInput('')
        setPriceInput('')
        setImageInput('')
        toast.success('Produto registrado com sucesso!')
      })
      .catch((error) => {
        console.log('Erro ao registrar: ' + error)
        toast.error('Ops, erro ao salvar o Produto!')
      })
  }

  async function handleDeleteLink(id) {
    const docRef = doc(db, 'products', id)
    await deleteDoc(docRef)
  }

  return (
    <div className='admin-container'>
      <Header />

      <form className='form' onSubmit={handleRegister}>
        <label className='label'>Titulo do produto</label>
        <Input
          placeholder='Titulo do produto...'
          value={titleInput}
          onChange={(e) => setTitleInput(e.target.value)}
        />

        <label className='label'>Preço do produto</label>
        <Input
          placeholder='Preço do produto...'
          value={priceInput}
          onChange={(e) => setPriceInput(e.target.value)}
        />

        <label className='label'>Imagem do produto</label>
        <Input
          placeholder='Imagem do produto...'
          value={imageInput}
          onChange={(e) => setImageInput(e.target.value)}
        />

        <button className='btn-register' type='submit'>
          Cadastrar <MdAddLink size={24} color='#FFF' />
        </button>
      </form>

      <h2 className='title'>
        Meus produtos
      </h2>

      {products.map((item, index) => (
        <article
          key={index}
          className='list animate-pop'
        >
          <p>{item.title}</p>
          <div>
            <button className='btn-delete' onClick={() => handleDeleteLink(item.id)}>
              <FiTrash2 size={18} color='#FFF' />
            </button>
          </div>
        </article>
      ))}
    </div>
  )
}