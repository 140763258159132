import styled from 'styled-components';

export const CustomModal = styled.div`
  
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-height: 90vh;
    max-width: 98%;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(141, 154, 124, 0.7);
  }

  .overlay-text {
    position: absolute;
    top: 25px;
    left: 35px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5px 10px;
    color: white;
    font-size: 14px;
    border-radius: 5px;
    max-width: calc(100% - 50px);

    p {
      margin-top: 5px;
    }
  }

  .modal-icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    color: white;
    cursor: pointer;
    transition: opacity 0.9s ease-in-out;
    opacity: ${props => (props.isVisible ? 1 : 0)};
    
    &.close-icon {
      top: 30px;
      right: 20px;
    }

    &.left-icon {
      left: 15%;
    }

    &.right-icon {
      right: 15%;
    }
  }

  &:hover .modal-icons {
    opacity: 1;
  }
`;

export const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: content-box;

  .mdContainer{
    display: flex;
    flex-direction: column;
  }

  .cardProd {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    margin: 15px;
    padding: 15px;
    min-width: 250px;
    min-height: 300px;
    max-width: 33vh;
    
    img {
      margin-bottom: 5px;
      align-self: center;
      justify-content: center;
      max-height: 350px;
      max-width: 100%;
      border-radius: 12px;
      box-shadow: 0px 0px 30px rgba(141, 154, 124, 0.9);
    }

    .content{
      display: flex;
      flex-direction: column;

      > strong {
        font-size: 16px;
        line-height: 20px;
        color: #333;
        margin-top: 10px;
      }

      > span {
        font-size: 21px;
        font-weight: bold;
        margin: 5px 0 15px;
        color: #888;
      }
      .whatsapp-button {
        display: inline-block;
        background-color: rgba(37, 211, 102, 0.8);
        color: #fff;
        font-size: 16px;
        padding: 10px 10px;
        border-radius: 5px;
        text-decoration: none;
      }
  
      .whatsapp-button:hover {
        background-color: #128c7e;
      }
  
      .whatsapp-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    @media (max-width: 480px) {
      min-width: 100%; 
      min-height: auto; 
      padding: 0;
      margin-bottom: 40px;

      background: none;    

      img {        
        max-height: 100%;
        box-shadow: 0px 0px 20px rgba(105, 105, 105, 0.9);
      }

      .content{
        background: rgba(255, 255, 255, 0.5);
        border-radius: 5px;

        margin-top: 5px;
        padding: 10px;
      }
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background-color: rgba(0, 0, 0, 0);
  }

  .spinner {
    font-size: 56px;
    animation: spin 1.5s linear infinite;
    color: white;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
